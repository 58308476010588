import React from 'react'
import SeekBar from '../controlBarComponents/seekbar'
import PlayMenuType2 from '../controlBarComponents/playMenuType2'
import SettingButton from '../controlBarComponents/settingButton'
import PipButton from '../controlBarComponents/pipButton'
import FullScreenButton from '../controlBarComponents/fullScreenButton'
import CustomSettingMenu from '../controlBarComponents/customSettingMenu'
import VolumeBarType2 from '../controlBarComponents/volumeBarType2'

const ControlBarType2 = ({
  playerData,
  controlBarData,
  playerEle,
  showControlBar,
  setCursorOverControlBar,
  duration,
  currentTime,
  bufferPercent,
  volume,
  isPlaying,
  togglePlayPause,
  forwardClick,
  backwardClick,
  handleNext,
  handlePrev,
  isPIPMode,
  isFullScreen,
  showSettingMenu,
  setShowSettingMenu,
  handleSeek,
  handleSeekOver,
  toggleFullScreen,
  togglePIPMode,
  toggleVolume,
  toggleMuted,
  seekBarHoverPercent,
  isMute,
  convertVideoSecondstoMintues,
}) => {
  let playTimeData = controlBarData.data.middle_control_buttons.play_time

  let settingData = controlBarData.data.right_control_buttons.setting
  let settingMenuData = controlBarData.data.right_control_buttons.button_inside_setting
  let pipData = controlBarData.data.right_control_buttons.picture_in_picture
  let fullScreenData = controlBarData.data.right_control_buttons.full_screen
  return (
    <div
      style={{
        height: showControlBar ? '71px' : '0px',
        // backgroundColor: '#00000080',
        transition: 'height 0.25s ease-in',
        padding: '0px 1.31vw',
        background: '#00000060',
      }}
      className="text-white w-full absolute bottom-0 "
      onMouseOver={() => setCursorOverControlBar(true)}
      onMouseLeave={() => setCursorOverControlBar(false)}
    >
      <div className="relative w-full h-full flex items-center justify-between">
        <div style={{ width: '20%' }} className="">
          <PlayMenuType2
            isPlaying={isPlaying}
            togglePlayPause={togglePlayPause}
            playerData={playerData}
            forwardClick={forwardClick}
            backwardClick={backwardClick}
            handleNext={handleNext}
            handlePrev={handlePrev}
          />
        </div>

        <div className=" relative w-[55%] 2xl:w-[58%]">
          <div className="w-full">
            <SeekBar
              handleSeek={handleSeek}
              handleSeekOver={handleSeekOver}
              convertVideoSecondstoMintues={convertVideoSecondstoMintues}
              seekBarHoverPercent={seekBarHoverPercent}
              duration={duration}
              currentTime={currentTime}
              bufferPercent={bufferPercent}
              playerData={playerData}
              showControlBar={showControlBar}
            />
          </div>
          {playTimeData.visible ? (
            <div
              style={{ fontFamily: playTimeData.font_style, color: playTimeData.text_color }}
              className={`absolute right-0 text-xs mt-3 ${playTimeData.font_format}`}
            >
              {convertVideoSecondstoMintues(currentTime)} / {convertVideoSecondstoMintues(duration)}
            </div>
          ) : null}
        </div>
        <div
          style={{ height: '24px', width: '18%' }}
          className={`relative flex items-center justify-between`}
        >
          <div style={{ height: '20px' }} className="">
            <VolumeBarType2
              toggleMuted={toggleMuted}
              volume={volume}
              toggleVolume={toggleVolume}
              isMute={isMute}
              playerData={playerData}
            />
          </div>
          <div style={{}} className="h-full">
            <SettingButton
              data={settingData}
              showSettingMenu={showSettingMenu}
              setShowSettingMenu={setShowSettingMenu}
            />
          </div>
          <div style={{}} className="h-full">
            <PipButton data={pipData} isPIPMode={isPIPMode} togglePIPMode={togglePIPMode} />
          </div>
          <div style={{}} className="h-full">
            <FullScreenButton
              data={fullScreenData}
              isFullScreen={isFullScreen}
              toggleFullScreen={toggleFullScreen}
            />
          </div>

          {showSettingMenu && (
            <CustomSettingMenu
              playerRef={playerEle}
              setShowSettingMenu={setShowSettingMenu}
              data={settingMenuData}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default ControlBarType2
