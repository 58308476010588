import React from 'react'
import BackwardButton from './backwardButton'
import ForwardButton from './forwardButton'
import PlayPauseButton from './playPauseButton'
import PrevButton from './prevButton'
import NextButton from './nextButton'

const PlayMenuType2 = (props) => {
  let {
    isPlaying,
    togglePlayPause,
    playerData,
    backwardClick,
    forwardClick,
    handlePrev,
    handleNext,
  } = props

  let playPauseData = playerData.control_bar.data.middle_control_buttons.play_pause
  let backwardData = playerData.control_bar.data.middle_control_buttons.backward_forward
  let prevNextData = playerData.control_bar.data.middle_control_buttons.prev_next
  return (
    <div
      className={`flex items-center text-xs ${
        backwardData.visible && prevNextData.visible ? 'justify-between' : 'justify-center'
      } `}
    >
      {handlePrev && handleNext ? (
        <div
          style={{ marginRight: !backwardData.visible ? 'min(2vw , 40px)' : '' }}
          onClick={handlePrev}
        >
          <PrevButton
            data={prevNextData}
            iconStyles={{ width: '24px', height: '24px' }}
            buttonStyles={{ width: '24px', height: '24px' }}
          />
        </div>
      ) : null}

      <div style={{ marginRight: !prevNextData.visible ? 'min(2vw , 40px)' : '' }}>
        <BackwardButton
          data={backwardData}
          backwardClick={backwardClick}
          iconStyles={{ width: '24px', height: '24px' }}
          buttonStyles={{ width: '24px', height: '24px' }}
        />
      </div>
      <div>
        <PlayPauseButton
          isPlaying={isPlaying}
          togglePlayPause={togglePlayPause}
          data={playPauseData}
          iconStyles={{ width: '24px', height: '24px' }}
        />
      </div>
      <div style={{ marginLeft: !prevNextData.visible ? 'min(2vw , 40px)' : '' }}>
        <ForwardButton
          data={backwardData}
          forwardClick={forwardClick}
          iconStyles={{ width: '24px', height: '24px' }}
          buttonStyles={{ width: '24px', height: '24px' }}
        />
      </div>
      {handlePrev && handleNext ? (
        <div
          style={{ marginLeft: !backwardData.visible ? 'min(2vw , 40px)' : '' }}
          onClick={handleNext}
        >
          <NextButton
            data={prevNextData}
            iconStyles={{ width: '24px', height: '24px' }}
            buttonStyles={{ width: '24px', height: '24px' }}
          />
        </div>
      ) : null}
    </div>
  )
}

export default PlayMenuType2
